import * as React from 'react';
import Select, { StylesConfig } from 'react-select';
import { Trans } from 'gatsby-plugin-react-i18next';

import { MintStatus } from '../../../services/constants';

interface NFTCollectionDropdownProps {
  onSelect: (status: MintStatus) => void;
}

interface Option {
  value: MintStatus;
  label: string | React.ReactElement;
}

const options: Option[] = [
  { value: MintStatus.UNMINTED, label: <Trans>Available</Trans> },
  { value: MintStatus.MINTED, label: <Trans>Sold</Trans> }
];

const customStyles: StylesConfig = {
  container: (provided) => ({ ...provided, boxShadow: 'none' }),
  control: (provided) => ({
    ...provided,
    width: '100%',
    backgroundColor: '#f4f4f4',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '160px',
    boxShadow: 'none',
    borderColor: '#f4f4f4',
    cursor: 'pointer',
    '&:hover, &:focus': {
      borderColor: '#f4f4f4',
      backgroundColor: '#DBDBDB',
      boxShadow: 'none'
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '10px 26px',
    flex: 1
  }),
  singleValue: (provided) => ({ ...provided, fontWeight: 'bold', fontSize: '16px', lineHeight: '19px' }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    backgroundColor: state.isSelected ? '#EDEDED' : '#FFFFFF',
    color: '#000000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  indicatorsContainer: () => ({
    marginRight: '8px'
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#FFFFFF',
    border: '1px solid #FFFFFF',
    borderRadius: '16px',
    padding: '12px 0'
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    svg: {
      fill: '#000'
    }
  })
};

const NFTCollectionDropdown = ({ onSelect }: NFTCollectionDropdownProps) => {
  return (
    <Select
      onChange={(newValue) => onSelect((newValue as Option)?.value as MintStatus)}
      options={options}
      defaultValue={options[0]}
      styles={customStyles}
    />
  );
};

export default NFTCollectionDropdown;
