import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';

import { useWeb3Context } from '../../../hooks/useWeb3Context';
import * as Analytics from '../../../services/analytics-utils';
import { Attributes } from '../../../services/types';
import { getNFTMetadata } from '../../../services/utils';

import { AnalyticsEventLocations, AnalyticsEvents } from '../../../analytic-events';
import VolcaneersBuyModal from '../../Modal/VolcaneersBuyModal';
import NoWalletModal from '../NoWalletModal';
import NFTImage from '../volcaneers/modals/NFTImage';
import NFTAttributeItem from './NFTAttributeItem';

interface NFTCollectionCardCurrentProps {
  nftStatus: string;
  nftPrice: string;
  tokenId: string;
  totalSupply: string;
  nftImage: string;
  account: string;
}

const NFTCollectionCardCurrent: React.FC<NFTCollectionCardCurrentProps> = ({
  nftStatus,
  nftPrice,
  tokenId,
  totalSupply,
  nftImage
}: NFTCollectionCardCurrentProps) => {
  const { t } = useTranslation();
  const { connected, currentAccount } = useWeb3Context();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [metadata, setMetadata] = useState<Attributes>();
  const [name, setName] = useState<string>();

  useEffect(() => {
    (async () => {
      const { name, attributes } = await getNFTMetadata(tokenId);

      setName(name);
      setMetadata(attributes);
    })();
  }, []);

  return (
    <div className="w-full flex flex-col md:flex-row border border-volcaneers-border-grey rounded-[24px]">
      <NFTImage
        imageModal={nftImage}
        classes="md:max-w-[340px] md:max-h-[340px] lg:max-w-[380px] lg:max-h-[380px]"
        imgClasses="rounded-tl-[24px] rounded-tr-[24px] md:rounded-tr-[0px] md:rounded-bl-[24px]"
      />
      <div className="w-full p-16 pb-24 lg:py-40 lg:px-24 md:flex-1">
        <div className="lg:flex lg:items-center lg:flex-row-reverse">
          <div className="flex items-center justify-between">
            <p className="text-16 text-grey-200 lg:mr-16">
              #{tokenId} / {totalSupply}
            </p>
            <h2 className="bg-white text-14 leading-4 py-8 px-16 rounded-[24px] max-w-[100px] text-center border border-volcaneers-border-grey font-bold uppercase">
              {nftStatus}
            </h2>
          </div>
          <h1 className="text-20 leading-6 font-bold mt-16 md:text-24 md:leading-7 lg:flex-1 lg:mt-0">{name}</h1>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-8 mt-16 lg:mt-32">
          {metadata &&
            Object.keys(metadata).map((key) => (
              <NFTAttributeItem key={key} label={t(key)} value={metadata[key as keyof Attributes]} />
            ))}
        </div>
        <div className="md:flex md:items-center md:mt-24 md:justify-between">
          <div className="my-16 md:mr-32 md:my-0">
            <NFTAttributeItem
              label={t('Price')}
              value={
                <>
                  <span className="text-24">{nftPrice}</span> <Trans>mADA</Trans>
                </>
              }
            />
          </div>
          <div className="min-w-[200px]">
            {connected ? (
              <VolcaneersBuyModal
                imageModal={nftImage}
                nftPrice={Number(nftPrice)}
                tokenId={tokenId}
                nftTotal={Number(totalSupply)}
                nftStatus={t('current')}
                buttonProps={{ sizeVariant: 'small', uppercaseText: false }}
                buttonOnClick={() =>
                  Analytics.event(
                    AnalyticsEvents.BUY_NFT,
                    currentAccount,
                    AnalyticsEventLocations.NFT_COLLECTION_CURRENT_NFT
                  )
                }
              />
            ) : (
              <NoWalletModal buttonProps={{ sizeVariant: 'small', uppercaseText: false }} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTCollectionCardCurrent;
