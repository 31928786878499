import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';

import { MintStatus } from '../../../services/constants';
import { UnmintedNft } from '../../../services/types';
import { getMintedNftsCollection, getUnmintedNftsCollection } from '../../../services/utils';

import { useAccount } from 'wagmi';
import NFTCardLoader from '../NFTCardLoader';
import Pagination from '../Pagination';
import NFTCollectionCard from './NFTCollectionCard';
import NFTCollectionCardCurrent from './NFTCollectionCardCurrent';
import NFTCollectionCardCurrentLoader from './NFTCollectionCardCurrentLoader';
import NFTCollectionDropdown from './NFTCollectionDropdown';

const NFTCollection = () => {
  const { t } = useTranslation();

  const [unmintedNFTsData, setUnmintedNFTsData] = useState<UnmintedNft>();
  const [mintedNFTsData, setMintedNFTsData] = useState<UnmintedNft>();
  const [pageCount, setPageCount] = useState(0);
  const [mintSelection, setMintSelection] = useState(MintStatus.UNMINTED);
  const [loader, setLoader] = useState(true);
  // current page used to override the state of Pagination component
  const [forcedPage, setForcedPage] = useState(0);
  const { address } = useAccount();

  const fetchNFTsCollection = async (page: number = 0): Promise<UnmintedNft> => {
    return mintSelection === MintStatus.UNMINTED
      ? await getUnmintedNftsCollection(page)
      : await getMintedNftsCollection(page);
  };

  const setNFTsCollection = async () => {
    setPageCount(0);

    const nftData = await fetchNFTsCollection(0);

    if (mintSelection === MintStatus.UNMINTED) {
      setUnmintedNFTsData(nftData);
    } else {
      setMintedNFTsData(nftData);
    }

    setPageCount(nftData.response.totalPages);
    setLoader(false);
  };

  useEffect(() => {
    setNFTsCollection();
  }, [mintSelection]);

  const handleMintSelectionChange = (mintSelection: MintStatus) => {
    setMintSelection(mintSelection);
  };

  const handlePageClick = async (page: number) => {
    setLoader(true);
    setForcedPage(page);

    let res = await fetchNFTsCollection(page);

    if (res.currentImage === '' && mintSelection === MintStatus.UNMINTED) {
      res.currentImage = unmintedNFTsData?.currentImage ?? '';
    }

    mintSelection === MintStatus.UNMINTED ? setUnmintedNFTsData(res) : setMintedNFTsData(res);

    setLoader(false);
    setPageCount(res.response.totalPages);
  };

  const NFTCards = () => {
    const data = mintSelection == MintStatus.UNMINTED ? unmintedNFTsData : mintedNFTsData;

    return data?.response.result.map((nft: any, index: number) => {
      return (
        <NFTCollectionCard
          key={index}
          index={index}
          sequenceData={data?.response.result}
          nftStatus={mintSelection === MintStatus.UNMINTED ? t('NEXT') : t('SOLD')}
          nftPrice={data?.nftPrice}
          tokenId={nft.tokenId}
          nftTotal={Number(data.totalSupply)}
          nftImage={nft.image}
          isSold={mintSelection !== MintStatus.UNMINTED}
        />
      );
    });
  };

  const getCurrentCard = () => {
    if (!unmintedNFTsData) {
      // use the regular card loader for mobile because of the current card layout
      return (
        <>
          <div className="hidden md:block">
            <NFTCollectionCardCurrentLoader />
          </div>
          <div className="md:hidden">
            <NFTCardLoader />
          </div>
        </>
      );
    }

    return (
      <NFTCollectionCardCurrent
        nftStatus={t('current')}
        nftPrice={unmintedNFTsData?.nftPrice}
        tokenId={unmintedNFTsData?.currentTokenId}
        totalSupply={unmintedNFTsData?.totalSupply}
        nftImage={unmintedNFTsData?.currentImage}
        account={address as string}
      />
    );
  };

  const isInLoadingState =
    loader ||
    (mintSelection === MintStatus.UNMINTED && !unmintedNFTsData) ||
    (mintSelection === MintStatus.MINTED && !mintedNFTsData);

  return (
    <section id="nft-collection-section" className="pb-[24vw] md:pb-[14vw] xl:pb-[11vw] relative z-30">
      <div className="container-slim">
        <div className="w-full md:flex md:items-center md:justify-between py-24 space-y-24 md:py-32 md:space-y-0">
          <div className="w-full md:w-[328px]">
            <NFTCollectionDropdown onSelect={handleMintSelectionChange} />
          </div>
          <Pagination handlePageClick={handlePageClick} pageCount={pageCount} forcedPage={forcedPage} />
        </div>
        <div className="w-full">
          {getCurrentCard()}
          <div className="mt-16 md:mt-24 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-16 md:gap-24">
            {isInLoadingState ? new Array(20).fill(1).map((_, index) => <NFTCardLoader key={index} />) : NFTCards()}
          </div>
        </div>
        <div className="w-full flex justify-center mt-40">
          <Pagination handlePageClick={handlePageClick} pageCount={pageCount} forcedPage={forcedPage} />
        </div>
      </div>
    </section>
  );
};

export default NFTCollection;
