import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect } from 'react';

import { config } from '../config';

import CookieConsentBanner from '../components/CookieConsentBanner';
import Footer from '../components/v2/Footer';
import Header from '../components/v2/header/Header';
import Layout from '../components/v2/Layout';
import NFTCollection from '../components/v2/nft_collection/NFTCollection';
import { useWeb3Context } from '../hooks/useWeb3Context';

const NftCollection = () => {
  const { connected, currentAccount } = useWeb3Context();
  const { t } = useTranslation();

  useEffect(() => {
    if (config.comingSoonMode) {
      navigate('/');
    }
  }, []);

  return (
    !config.comingSoonMode && (
      <Layout title={t('NFT Collection')}>
        <Header showSubHeader="volcaneers" connected={connected} currentAccount={currentAccount} />
        <CookieConsentBanner />
        <NFTCollection />
        <Footer withLavaIllustration connected={connected} currentAccount={currentAccount} />
      </Layout>
    )
  );
};

export default NftCollection;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
