import React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

interface NFTCollectionCardCurrentLoaderProps extends IContentLoaderProps {}

const NFTCollectionCardCurrentLoader = (props: NFTCollectionCardCurrentLoaderProps) => (
  <div className="w-full border border-volcaneers-border-grey rounded-[24px] p-16 pb-24 lg:py-40 lg:px-24">
    <ContentLoader
      speed={2}
      width={500}
      height={250}
      viewBox="0 0 500 250"
      backgroundColor="#EDF2F0"
      foregroundColor="#DEE3E1"
      {...props}
    >
      <rect x="6" y="1" rx="8" ry="8" width="248" height="248" />
      <rect x="275" y="61" rx="2" ry="2" width="200" height="35" />
      <rect x="420" y="135" rx="0" ry="0" width="0" height="1" />
      <rect x="275" y="155" rx="17" ry="17" width="150" height="35" />
      <rect x="275" y="33" rx="2" ry="2" width="80" height="15" />
      <rect x="275" y="109" rx="2" ry="2" width="200" height="15" />
    </ContentLoader>
  </div>
);

export default NFTCollectionCardCurrentLoader;
