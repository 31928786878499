import { Trans } from 'gatsby-plugin-react-i18next';
import React, { createRef } from 'react';

import VolcaneersDetailModal, { VolcaneersDetailModalRefProps } from '../../Modal/VolcaneersDetailModal';
import NFTImage from '../volcaneers/modals/NFTImage';

interface NFTCollectionCardProps {
  nftStatus: string;
  nftPrice: string;
  nftImage: string;
  tokenId: string;
  nftTotal: number;
  sequenceData: any[];
  index: number;
  isSold?: boolean;
}

const NFTCollectionCard = ({
  nftStatus,
  nftPrice,
  tokenId,
  nftTotal,
  nftImage,
  sequenceData,
  index,
  isSold = false
}: NFTCollectionCardProps) => {
  const modalRef = createRef<VolcaneersDetailModalRefProps>();

  return (
    <div className="border border-volcaneers-border-grey rounded-[24px]">
      <div className="relative">
        <NFTImage imageModal={nftImage} onImageClick={() => modalRef?.current?.openModal()} />
        {isSold && (
          <div
            className="w-full h-full absolute top-0 rounded-tl-[24px] rounded-tr-[24px] flex justify-center items-center volcaneers-bg-sold-overlay backdrop-blur-[3px] hover:cursor-pointer"
            onClick={() => modalRef?.current?.openModal()}
          >
            <h1 className="text-32 text-white text-center font-bold font-base rotate-[-15deg]">
              <Trans>SOLD</Trans>
            </h1>
          </div>
        )}
        <p className="bg-white absolute left-0 right-0 mx-auto -bottom-[20px] text-14 leading-4 py-8 px-16 rounded-[24px] max-w-[100px] text-center border border-volcaneers-border-grey font-bold uppercase">
          {nftStatus}
        </p>
      </div>
      <div className="mt-24 md:mt-32 mb-16 px-12 md:px-24">
        <h3 className="text-center font-bold font-base mb-4">
          {nftPrice} <Trans>mADA</Trans>
        </h3>
        <div className="flex items-center justify-center">
          <p className="mb-0 text-center text-grey-200">
            #{tokenId} / {nftTotal}
          </p>
          <div className="w-[16px] h-[16px] md:w-[24px] md:h-[24px] ml-4">
            <VolcaneersDetailModal
              ref={modalRef}
              tokenId={Number(tokenId)}
              sequenceData={sequenceData}
              index={index}
              nftTotal={nftTotal.toString()}
              disableDownload={true}
              userNftSection={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTCollectionCard;
